import React from 'react';
import { Col } from 'antd';
import Container from '../components/Container';
import ImageText from '../components/ImageText';
import ImageTextDescription from '../components/ImageTextDesctiption';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import HomeLogos from '../components/HomeLogos';
import VerticalBox from '../components/VerticalBox';
import Cdp1 from '../images/cdp-1.svg';
import Ma2 from '../images/ma-2.png';
import Personas1 from '../images/personas-1.svg';

const PERSONAS_DATA1 = [
  {
    id: 'personas-1-1',
    iconIndex: 18,
    title: '第一方数据',
    description: '打通网站、微信、小程序、App、H5、商品互动码、POS、短信、邮件、CRM、会员系统、线下活动等渠道',
  },
  {
    id: 'personas-1-2',
    iconIndex: 71,
    title: '第二方数据',
    description: '来自于社交媒体、电商平台、营销活动网站、客户调研等数据来源，但数据往往存在于其他渠道平台',
  },
  {
    id: 'personas-1-3',
    iconIndex: 72,
    title: '第三方数据',
    description: '包括各类线下数据、DMP数据包、第三方媒体数据等，可通过API调用或导入的方式来应用',
  },
];

const PERSONAS_DATA2 = [
  {
    id: 'personas-2-1',
    iconIndex: 73,
    description: '标签规则制定',
  },
  {
    id: 'personas-2-2',
    iconIndex: 74,
    description: '行业标签库',
  },
  {
    id: 'personas-2-3',
    iconIndex: 75,
    description: '生命周期价值模型',
  },
  {
    id: 'personas-2-4',
    iconIndex: 76,
    description: '多维度价值评分',
  },
  {
    id: 'personas-2-5',
    iconIndex: 77,
    description: 'AI模型计算',
  },
  {
    id: 'personas-2-6',
    iconIndex: 78,
    description: '智能行为预测',
  },
];

const PERSONAS_DATA3 = [
  {
    id: 'personas-3-1',
    iconIndex: 10,
    title: '精准营销',
    description: '有效支持千人千面的个性化精准营销',
  },
  {
    id: 'personas-3-2',
    iconIndex: 11,
    title: 'DMP人群包',
    description: '输出精准的广告投放DMP人群包',
  },
  {
    id: 'personas-3-3',
    iconIndex: 13,
    title: 'One ID',
    description: '多渠道重复客户归并，避免重复营销触达',
  },
  {
    id: 'personas-3-4',
    iconIndex: 14,
    title: '商业BI分析',
    description: '可视化数据洞察，有效掌握业务全景',
  },
];

export default () => (
  <Layout current="scens" subCurrent="personas">
    <CommonPageBanner
      title="如何构建企业级的用户画像体系"
      description="用户画像是对多维度海量用户数据进行整理和分析后形成的精准用户洞察。我们都想知道客户究竟是什么样的人，应用DM Hub完整还原每一位真实客户。"
      imgName="personas"
      maskColor="blue"
    />
    <TitledVerticalBoxes title="全渠道用户数据汇总" sectionId="personas-1">
      {PERSONAS_DATA1.map((item) => (
        <Col lg={8} md={8} sm={8} xs={24} className="icon-text-col same" key={item.id}>
          <VerticalBox {...item} />
        </Col>
      ))}
    </TitledVerticalBoxes>
    <div className="content-list-wrapper personas-content-1">
      <section className="image-text-section">
        <Container>
          <ImageText image={Cdp1} imgWidth={445} imgHeight={315} title="用户数据治理" position="right" imageSize={10}>
            <ImageTextDescription label="实时处理能力" content="亿级海量数据的稳定计算能力" />
            <ImageTextDescription label="数据清洗" content="异常数据过滤、脏数据清洗和较验" />
            <ImageTextDescription label="数据合并" content="自动化的ID Mapping和One-ID体系" />
            <ImageTextDescription label="数据分析" content="AI Hub人群聚类智能分析和模型验算" />
          </ImageText>
        </Container>
      </section>
    </div>
    <TitledVerticalBoxes title="强大的画像引擎" shadowed sectionId="personas-2">
      {PERSONAS_DATA2.map((item) => (
        <Col lg={8} md={8} sm={8} xs={12} className="icon-text-col same" key={item.id}>
          <VerticalBox {...item} />
        </Col>
      ))}
    </TitledVerticalBoxes>
    <div className="content-list-wrapper personas-content-2">
      <section className="image-text-section white">
        <Container>
          <ImageText image={Ma2} imgWidth={447} imgHeight={302} title="形成用户画像" position="right" imageSize={10}>
            <ImageTextDescription label="建立标签体系" content="建立体系化的客户标签和内容标签体系" />
            <ImageTextDescription label="精准客户分层" content="通过客户画像背后的多级维度，智能化圈群分组" />
            <ImageTextDescription label="构建用户画像" content="通过多维度指标输出精准360°客户画像" />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section blue">
        <Container>
          <ImageText image={Personas1} imgWidth={383} imgHeight={380} title="用户画像的维度" imageSize={10}>
            <ImageTextDescription label="用户来源" content="用户数据来源可以精确到来源平台、媒体、创意、广告位、内容等渠道" />
            <ImageTextDescription label="用户属性" content="用户的人口学属性和具有唯一性的可识别属性(性别、年龄、人生阶段等)" />
            <ImageTextDescription label="用户身份" content="身份信息、微信OpenID、手机IMEI、DeviceID、Email地址和第三方ID" />
            <ImageTextDescription label="用户标签" content="标签是最核心的部分，DM Hub具备自动化标签流程和各行业标签模板库" />
            <ImageTextDescription label="内容标签" content="用户所触达的营销及内容偏好的标记，实现对相同偏好人群的分层" />
            <ImageTextDescription label="用户行为" content="可自定义的用户事件，如浏览点击、注册、交互、交易购买等行为" />
          </ImageText>
        </Container>
      </section>
    </div>
    <TitledVerticalBoxes title="用户画像的营销应用" sectionId="personas-3">
      {PERSONAS_DATA3.map((item) => (
        <Col lg={6} md={6} sm={12} xs={12} className="icon-text-col same" key={item.id}>
          <VerticalBox {...item} />
        </Col>
      ))}
    </TitledVerticalBoxes>
    <HomeLogos />
  </Layout>
);
